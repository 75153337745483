/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/style/layout.css"

import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import loadable from "@loadable/component"

loadable(() => import("scroll-behavior-polyfill"), { ssr: false })

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LdIwsAqAAAAAAfJEh47yrBrc2UwMsTRGEDLAVLO">
    <ParallaxProvider>{element}</ParallaxProvider>
  </GoogleReCaptchaProvider>
)
