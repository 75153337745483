import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box, Text, Image } from "@theme-ui/components"
import { useEffect } from "react"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import ImageAndText from "../components/blocks/imageAndText"
import TitleAndBody from "../components/blocks/titleAndBody"
import BigTitle from "../components/blocks/bigTitle"
import BigImageAndText from "../components/blocks/bigImageAndText"
import ContactAccordion from "../components/blocks/contactAccordion"
import Logo from "../images/background.svg"
import loadable from "@loadable/component"
import BigTitleAndBody from "../components/blocks/bigTitleAndBody"
import AOS from "aos"
import "aos/dist/aos.css"
const ItemCarousel = loadable(
  () => import("../components/blocks/itemCarousel"),
  { ssr: false }
)

const Home = ({ data: { page, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags
  useEffect(() => {
    AOS.init()
  }, [])

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  const blockIds = []

  page.content.map((block, index) => {
    if (block.menuText && !block.menuText2)
      blockIds.push({
        id: block.id,
        title: block.menuText,
      })
    else if (block.menuText2 && block.menuText) {
      blockIds.push({
        id: block.id + "menuText",
        title: "Vantaggi",
      })
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.content.map((block, index) => (
        <>
          {index === 0 && (
            <Image
              sx={{
                position: "absolute",
                top: "110%",
                right: 0,
                minHeight: ["946px"],
                display: ["none", "none", "block", "block"],
                zIndex: 0,
              }}
              src={Logo}
            />
          )}
          <Box
            as="section"
            key={block.id}
            sx={{
              zIndex: 1,
              position: "relative",
            }}
          >
            {block && block.model && block.model.apiKey === "item_carousel" && (
              <>
                <ItemCarousel block={block} />
              </>
            )}
            {block && block.model && block.model.apiKey === "image_and_text" && (
              <>
                <ImageAndText block={block} />
              </>
            )}
            {block && block.model && block.model.apiKey === "big_title" && (
              <BigTitle block={block} />
            )}
            {block && block.model && block.model.apiKey === "paragraph" && (
              <Box
                sx={{ backgroundColor: !block.lightTheme && "dark" }}
                data-aos-anchor-placement="center-center"
                data-aos="fade-up"
              >
                <Container>
                  <Text
                    sx={{
                      maxWidth: "706px",
                      color: !block.lightTheme && "light",
                      p: {
                        fontSize: [2, 2, 4, 4],
                        fontWeight: 300,
                      },
                      "h1,h2,h3": {
                        lineHeight: "1.1",
                        color: "right",
                        fontSize: [6, "72px"],
                        fontWeight: "400",
                      },
                    }}
                    as="div"
                    dangerouslySetInnerHTML={{ __html: block.description }}
                  />
                </Container>
              </Box>
            )}
            {block &&
              block.model &&
              block.model.apiKey === "big_title_and_body" && (
                <BigTitleAndBody block={block} light={block.light} />
              )}
            {block &&
              block.model &&
              block.model.apiKey === "big_image_and_text" && (
                <BigImageAndText block={block} />
              )}
            {block &&
              block.model &&
              block.model.apiKey === "title_and_body" && (
                <TitleAndBody block={block} light={block.light} />
              )}
            {block && block.model && block.model.apiKey === "contact_form" && (
              <Box>
                <ContactAccordion
                  block={block}
                  expand={block.title && block.title.includes("azienda")}
                />
              </Box>
            )}
          </Box>
        </>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      heroImage {
        alt
        title
        mimeType
        blurhash
        customData
        video {
          streamingUrl
          thumbnailUrl(format: jpg)
          mp4Url(exactRes: low)
          muxPlaybackId
        }
        gatsbyImageData(width: 1440, height: 900, placeholder: BLURRED)
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        value
      }
      content {
        ... on DatoCmsItemCarousel {
          id
          menuText
          menuText2
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }

          model {
            apiKey
          }
        }
        ... on DatoCmsBigTitleAndBody {
          id
          menuText
          light
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          bigTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          image {
            gatsbyImageData(width: 1440, placeholder: BLURRED)
          }
          body
          page {
            ... on DatoCmsInternalLink {
              id

              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
              }
            }
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }

          model {
            apiKey
          }
        }
        ... on DatoCmsBigTitle {
          id
          menuText
          firstTitle
          secondTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsParagraph {
          id
          menuText
          description
          lightTheme
          model {
            apiKey
          }
        }
        ... on DatoCmsBigImageAndText {
          id
          menuText
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          menuText
          light
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          bigTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          open
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          menuText
          model {
            apiKey
          }
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
